<template>

<!-- EDIT HERE -->

<div class="container">
<section class="section rules text-center" id="rules">
<div class="tabs">
  <div class="spoon is-medium is-primary">Rules</div>
</div>
<!-- THIS TEXT IS COPYPASTED FROM MOOSE.GG, perhaps you need to change it! >>> -->
<h3>Ban evading/Cheating</h3>
<ul class="text-start">
<li>No Rust gamebans under 180 days</li>
<li>Do not use any third-party paid or free programs which provide an unfair advantage in-game. This includes scripts which can perform any action that is otherwise not possible to be performed ingame.</li>
<li>Do not do anything to bypass a ban on our servers. Do not try to join with a different account if you were banned, sit it out. Any try to do so will result in additional bans.</li>
</ul>
<h3>Toxicity</h3>
<ul class="text-start">
<li>Do not insult other players via text chat or any other applicable communication channel. We want a friendly and comfortable place for everyone on our servers. Not following this rule will result into temporary or permanent restrictions, in extreme cases bans.</li>
</ul>
<h3>Bug abusing</h3>
<ul class="text-start">
<li>Do not try to use any unfair advantages which were not intentially possible within the game. This includes but is not limited to accessing unaccessible areas and creating graphic errors or using graphical adjustments.</li>
</ul>
<!-- <<< THIS TEXT IS COPYPASTED FROM MOOSE.GG, perhaps you need to change it! -->
</section>
</div>

<!-- EDIT HERE -->

</template>


<script>
export default {
  name: 'rulesSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.store {
	padding-top: 10rem;
}
#rules > h3 {
	color: var(--color-highlight);
}
ul.text-start > li {
	color: var(--color-mute);
}
</style>