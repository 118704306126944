<template>

<!-- EDIT HERE -->

<div class="container">
<section class="section rules text-center" id="faq">
<div class="tabs">
  <div class="spoon is-medium is-primary">FAQ</div>
</div>
<!-- SINGLE QUESTION & ANSWER -->
<h3 class="faq-question">Where i can appeal a ban?</h3>
<p class="text-start">You can appeal a ban at our discord</p>
<!-- SINGLE QUESTION & ANSWER /END -->
<!-- SINGLE QUESTION & ANSWER -->
<h3 class="faq-question">What can i do on your servers?</h3>
<p class="text-start">You can train your aim&pvp skills</p>
<!-- SINGLE QUESTION & ANSWER /END -->
<!-- SINGLE QUESTION & ANSWER -->
<h3 class="faq-question">What?</h3>
<p class="text-start">Yes</p>
<!-- SINGLE QUESTION & ANSWER /END -->
</section>
</div>

<!-- EDIT HERE -->

</template>


<script>
export default {
  name: 'faqSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3.faq-question {
	text-align: left;
  border-bottom: 1px solid var(--color-rust);
  padding-bottom: 5px;
  margin-bottom: 10px;
}
p.text-start {
	color: var(--color-mute);
}
p.text-start > a {
	color: var(--color-mute);
	text-decoration: underline;
}
p.text-start > a:hover {
	color: var(--color-white);
	text-decoration: none;
}
</style>